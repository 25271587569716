import { useEffect, useState } from "react";
import styles from "./ConsentSignature.module.css";
import { ApiStatus } from "../../types/api_status";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Button from "../../components/Button/Button";
import { isMobile } from "react-device-detect";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import ConsentFormCanvas from "./ConsentFormCanvas/ConsentFormCanvas";
import Next from "../../assets/ArrowRight.svg";
import { ClipLoader } from "react-spinners";
import { getConsentForms, signConsentForms } from "./api";
import { UnauthenticatedError } from "../../api/http_client";
import ConsentFormLoadingState from "./ConsentFormCanvas/ConsentFormLoadingState";
import Loader from "../../components/Loader";

interface IFormInput {
  fullName: string;
}

interface FetchFormsState {
  formsUrl: string | null;
  status: ApiStatus;
}

export default function ConsentSignature() {
  const [fetchFormsState, setFetchFormsState] = useState<FetchFormsState>({
    status: ApiStatus.LOADING,
    formsUrl: null,
  });

  const navigate = useNavigate();

  useEffect(() => {
    getConsentForms()
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        setFetchFormsState({ formsUrl: url, status: ApiStatus.SUCCESS });
      })
      .catch((e) => {
        if (e instanceof UnauthenticatedError) {
          navigate("/authenticate");
        } else {
          setFetchFormsState((prev) => ({
            ...prev,
            status: ApiStatus.FAILURE,
          }));
        }
      });
  }, [navigate]);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty, isValid },
  } = useForm<IFormInput>({
    mode: "onSubmit",
  });

  const signatureError = errors.fullName;

  const onSubmit = (data: IFormInput) => {
    return signConsentForms(data.fullName)
      .then(() => {
        navigate("/complete");
      })
      .catch(() => {
        alert("Error submitting forms");
      });
  };

  return (
    <Loader loading={!fetchFormsState.formsUrl}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.consentSignature}>
          {!isMobile &&
            (fetchFormsState.formsUrl ? (
              <ConsentFormCanvas
                url={fetchFormsState.formsUrl}
              ></ConsentFormCanvas>
            ) : (
              <ConsentFormLoadingState></ConsentFormLoadingState>
            ))}
          <div className={styles.actionsBox}>
            <h1 className={styles.title}>Sign recipient forms</h1>
            <p className={styles.subtext}>
              By signing below, you acknowledge that you have read the attached
              form(s) and agree to receive recertified medications.
            </p>
            {isMobile && fetchFormsState.formsUrl && (
              <ConsentFormCanvas
                url={fetchFormsState.formsUrl}
              ></ConsentFormCanvas>
            )}
            {!isMobile && (
              <div className={styles.inputBox}>
                <input
                  autoFocus={true}
                  className={signatureError ? styles.hasError : undefined}
                  id={"fullName"}
                  placeholder="Type your full name to sign"
                  {...register("fullName", { required: true })}
                  data-testid={"Consent__signature"}
                ></input>
                {signatureError && (
                  <p className={styles.error}>Please enter your full name</p>
                )}
                <div className={styles.buttonWrap}>
                  <Button
                    width="200px"
                    submit={true}
                    loading={isSubmitting}
                    testId="Consent__continue"
                  >
                    Continue
                  </Button>
                </div>
              </div>
            )}
          </div>{" "}
          {isMobile && (
            <div className={styles.mobileSignBox}>
              <input
                autoFocus={true}
                className={signatureError ? styles.hasError : undefined}
                id={"fullName"}
                placeholder="Type your full name to sign"
                {...register("fullName", { required: true })}
              ></input>
              <button
                disabled={!isDirty || !isValid || isSubmitting}
                type="submit"
                data-testid="Consent__continue"
              >
                {isSubmitting ? (
                  <ClipLoader
                    color="white"
                    size={32}
                    speedMultiplier={0.75}
                  ></ClipLoader>
                ) : (
                  <img src={Next} alt="Next"></img>
                )}
              </button>
            </div>
          )}{" "}
        </div>
      </form>
    </Loader>
  );
}
