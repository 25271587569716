import { RegisterOptions, useFormContext, useWatch } from "react-hook-form";
import styles from "./Input.module.css";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import { useRef } from "react";
// import ErrorMessage from "../ErrorMessage/ErrorMessage";

interface InputProps {
  id: string;
  name: string;
  label?: string;
  placeholder?: string;
  className?: string;
  // onChange: (value: string) => void;
  autocomplete?: string;
  defaultValue?: string;
  onFocus?: () => void;
  onBlur?: () => void;
  autoFocus?: boolean;
  registerOptions?: Partial<RegisterOptions>;
  error?: string;
  type?: string;
  max?: string;
  min?: string;
  testId?: string;
}

export default function Input(props: InputProps) {
  const { register } = useFormContext();
  const value = useWatch({ name: props.name });
  const hasValue = !!value;
  const hasError = !!props.error;
  const { ref, ...rest } = register(props.name, props.registerOptions);
  const myRef = useRef<HTMLInputElement | null>(null);
  return (
    <div className={`${styles.inputContainer} ${props.className ?? ""}`}>
      <label
        className={`${styles.label} ${
          hasValue || props.type === "date" ? styles.shiftedLabel : ""
        }`}
        htmlFor={props.name}
      >
        {props.label}
      </label>
      <input
        {...rest}
        className={`${styles.input} ${hasError ? styles.error : ""} ${
          hasValue || props.type === "date" ? styles.inputFilled : ""
        }`}
        max={props.max}
        min={props.min}
        defaultValue={props.defaultValue}
        type={props.type ?? "text"}
        autoFocus={props.autoFocus}
        id={props.id}
        aria-label={props.label}
        aria-required={props.registerOptions?.required ? "true" : "false"}
        autoComplete={props.autocomplete}
        name={props.name}
        onFocus={props.onFocus}
        ref={(e) => {
          ref(e);
          myRef.current = e;
        }}
        onBlur={props.onBlur}
        data-testId={props.testId}
      ></input>
      {props.error && <ErrorMessage>{props.error}</ErrorMessage>}
    </div>
  );
}
