import instance from "../../api/http_client";

export function getConsentForms() {
  return instance
    .get("consent/patient/forms", { responseType: "blob" })
    .then((response) => response.data);
}

export function signConsentForms(signature: string) {
  return instance.post("consent/patient/forms/sign", { signature: signature });
}
