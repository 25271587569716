import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import MyRoutes from "./components/MyRoutes/MyRoutes";
import ProgressBar from "./components/ProgressBar/ProgressBar";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    document.title = "MediCircle | Recipient";
  }, []);

  return (
    <Router>
      <ProgressBar></ProgressBar>
      <MyRoutes />
    </Router>
  );
}

export default App;
