import { FormProvider, useForm } from "react-hook-form";
import styles from "./ConfirmInformation.module.css";
import Input from "../../components/Input/Input";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import { updatePatient } from "../../api/patient_api";
import { useEffect, useState } from "react";
import { ApiStatus } from "../../types/api_status";
import { REQUIRED_FIELD_ERROR_MESSAGE } from "../../constants";
import { Patient } from "./types";
import { getPatientInformation } from "./api";
import { UnauthenticatedError } from "../../api/http_client";
import { BeatLoader } from "react-spinners";
import Loader from "../../components/Loader";

interface IFormInput {
  firstName: string;
  lastName: string;
  streetAddress: string;
  state: string;
  city: string;
  zipCode: string;
  email: string;
  phone: string;
}

interface PatientFetchState {
  patient: Patient | null;
  apiStatus: ApiStatus;
}

export default function ConfirmInformation() {
  const [patientFetchStatus, setPatientFetchStatus] =
    useState<PatientFetchState>({
      patient: null,
      apiStatus: ApiStatus.LOADING,
    });

  const [submissionError, setSubmissionError] = useState("");

  const navigate = useNavigate();

  const methods = useForm<IFormInput>({
    mode: "onSubmit",
  });

  useEffect(() => {
    getPatientInformation()
      .then((patient) => {
        methods.setValue("firstName", patient.firstName);
        methods.setValue("lastName", patient.lastName);
        methods.setValue("email", patient.email);
        methods.setValue("phone", patient.phoneNumber);
        methods.setValue("state", patient.state);
        methods.setValue("zipCode", patient.zipCode);
        methods.setValue("city", patient.city);
        methods.setValue("streetAddress", patient.streetAddress);
        setPatientFetchStatus(() => ({
          patient: patient,
          apiStatus: ApiStatus.SUCCESS,
        }));
      })
      .catch((e) => {
        if (e instanceof UnauthenticatedError) {
          navigate("/authenticate");
        } else {
          setPatientFetchStatus((prev) => ({
            ...prev,
            apiStatus: ApiStatus.FAILURE,
          }));
        }
      });
  }, [methods, navigate]);

  const isDirty = methods.formState.isDirty;
  const errors = methods.formState.errors;

  const onSubmit = (data: IFormInput) => {
    if (isDirty) {
      return updatePatient(
        data.firstName,
        data.lastName,
        data.email,
        data.phone,
        data.streetAddress,
        data.city,
        data.state,
        data.zipCode
      )
        .then(() => navigate("/consent"))
        .catch((e) => {
          if (e instanceof UnauthenticatedError) {
            return navigate("/authenticate");
          } else {
            setSubmissionError("Error submitting data");
          }
        });
    } else {
      return navigate("/consent");
    }
  };

  return (
    <Loader loading={patientFetchStatus.apiStatus === ApiStatus.LOADING}>
      <FormProvider {...methods}>
        <div className={styles.confirmInformation}>
          <div className={styles.content}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <h1 className={styles.title}>Confirm your information</h1>
              <p className={styles.subtext}>
                Please make sure this information is accurate. This is where we
                will ship your medication.
              </p>
              {patientFetchStatus.apiStatus !== ApiStatus.SUCCESS ? (
                <BeatLoader
                  color="var(--color-primary)"
                  className={styles.loader}
                ></BeatLoader>
              ) : (
                <>
                  <div className={styles.inputGrid}>
                    <Input
                      id={"firstName"}
                      name={"firstName"}
                      label="First name"
                      autocomplete="off"
                      registerOptions={{ required: true }}
                      error={errors.firstName && REQUIRED_FIELD_ERROR_MESSAGE}
                    ></Input>
                    <Input
                      id={"lastName"}
                      name={"lastName"}
                      label="Last name"
                      registerOptions={{ required: true }}
                      error={errors.lastName && REQUIRED_FIELD_ERROR_MESSAGE}
                    ></Input>
                    <Input
                      className={styles.fullSpanInput}
                      id={"email"}
                      name={"email"}
                      label="Email"
                      registerOptions={{ required: true }}
                      error={errors.email && REQUIRED_FIELD_ERROR_MESSAGE}
                    ></Input>
                    <Input
                      className={styles.fullSpanInput}
                      id={"phone"}
                      name={"phone"}
                      label="Phone"
                      registerOptions={{ required: true }}
                      error={errors.phone && REQUIRED_FIELD_ERROR_MESSAGE}
                    ></Input>
                    <Input
                      className={styles.fullSpanInput}
                      id={"streetAddress"}
                      name={"streetAddress"}
                      label="Street address"
                      registerOptions={{ required: true }}
                      error={
                        errors.streetAddress && REQUIRED_FIELD_ERROR_MESSAGE
                      }
                    ></Input>
                    <Input
                      className={styles.fullSpanInput}
                      id={"city"}
                      name={"city"}
                      label="City"
                      registerOptions={{ required: true }}
                      error={errors.city && REQUIRED_FIELD_ERROR_MESSAGE}
                    ></Input>
                    <Input
                      id={"state"}
                      name={"state"}
                      label="State"
                      registerOptions={{ required: true }}
                      error={errors.state && REQUIRED_FIELD_ERROR_MESSAGE}
                    ></Input>
                    <Input
                      id={"zipCode"}
                      name={"zipCode"}
                      label="Zip code"
                      registerOptions={{ required: true }}
                      error={errors.zipCode && REQUIRED_FIELD_ERROR_MESSAGE}
                    ></Input>
                  </div>
                  <div className={styles.checkboxes}>
                    {submissionError && (
                      <p className={styles.networkError}>{submissionError}</p>
                    )}
                  </div>
                </>
              )}

              <Button
                width={"100%"}
                loading={methods.formState.isSubmitting}
                submit={true}
                testId="ConfirmInfo__continue"
              >
                Continue
              </Button>
            </form>
          </div>
        </div>
      </FormProvider>
    </Loader>
  );
}
