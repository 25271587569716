import MoonLoader from "react-spinners/MoonLoader";
import styles from "./ConsentFormCanvas.module.css";

export default function ConsentFormLoadingState() {
  return (
    <div className={styles.document}>
      <div className={styles.pdfFrame2}>
        <div className={styles.loading}>
          <MoonLoader color="var(--color-primary)"></MoonLoader>
        </div>
      </div>
    </div>
  );
}
