import Loader from "../../components/Loader";
import styles from "./Completion.module.css";

export default function Completion() {
  return (
    <Loader>
      <div className={styles.body}>
        <div className={styles.content}>
          <div className={styles.checkBox}>
            <img
              className={styles.check}
              src={require("../../assets/Check.svg").default}
              alt="help"
            />
          </div>

          <h1 className={styles.title}>You're all set</h1>
          <p className={styles.subtext}>
            We'll email you with updates on your medication.<br></br>You may now
            close out of this page.
          </p>
        </div>
      </div>
    </Loader>
  );
}
