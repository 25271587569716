import React, { useEffect, useState } from "react";
import LoaderIndicator from "./indicator";
import styles from "./styles.module.css";

interface LoadingProps {
  children: React.ReactNode;
  loading?: boolean;
}

const Loader: React.FC<LoadingProps> = (props: LoadingProps) => {
  const [isLoading, setLoading] = useState(true);
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    if (props.loading === undefined) {
      const delay = Math.floor(Math.random() * 400) + 100; // Random delay between 200ms and 500ms
      const timer = setTimeout(() => {
        setLoading(false);
      }, delay);

      const contentTimer = setTimeout(() => {
        setShowContent(true);
      }, delay + 200);

      return () => {
        clearTimeout(timer);
        clearTimeout(contentTimer);
      };
    }
  }, [props.loading]);

  return (
    <div className={styles.loaderWrap}>
      <LoaderIndicator
        isLoading={props.loading !== undefined ? props.loading : isLoading}
      ></LoaderIndicator>
      <div
        className={`${styles.content} ${
          (props.loading !== undefined && !props.loading) || showContent
            ? styles.show
            : styles.hide
        }`}
      >
        {props.children}
      </div>
    </div>
  );
};

export default Loader;
