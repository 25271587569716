import { Route, Routes } from "react-router";
import { Navigate } from "react-router-dom";
import PatientAuthentication from "../../pages/PatientAuthentication/PatientAuthentication";
import ConfirmInformation from "../../pages/ConfirmInformation/ConfirmInformation";
import ConsentSignature from "../../pages/ConsentSignature/ConsentSignature";
import Completion from "../../pages/Completion/Completion";
import Welcome from "../../pages/Welcome/Welcome";
import Questionnaire from "../../pages/Questionnaire/Questionnaire";

export default function MyRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/welcome"></Navigate>}></Route>
      <Route path="/welcome" element={<Welcome></Welcome>}></Route>
      <Route
        path="/authenticate"
        element={<PatientAuthentication></PatientAuthentication>}
      ></Route>
      <Route
        path="/info"
        element={<ConfirmInformation></ConfirmInformation>}
      ></Route>
      <Route
        path="/consent"
        element={<ConsentSignature></ConsentSignature>}
      ></Route>
      <Route
        path="/questionnaire"
        element={<Questionnaire></Questionnaire>}
      ></Route>
      <Route path="/complete" element={<Completion></Completion>}></Route>
      <Route path="*" element={<Navigate to="/welcome"></Navigate>}></Route>
    </Routes>
  );
}
