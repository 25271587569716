import instance from "../../api/http_client";

export function authenticatePatient(
  dob: string,
  firstName: string,
  lastName: string,
  accessCode: string
) {
  return instance.post(`consent/patient/authenticate`, {
    dob: dob,
    first_name: firstName,
    last_name: lastName,
    access_code: accessCode,
  });
}
