import axios, { AxiosResponse } from "axios";
import { BASE_API_URL } from "../constants";

export class UnauthenticatedError extends Error {
  response: AxiosResponse;

  constructor(message: string, response: AxiosResponse) {
    super(message);
    this.name = "UnauthenticatedError";
    this.response = response;
  }
}

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";

const BASE_CONFIG = {
  baseURL: BASE_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
};

axios.defaults.withCredentials = true;

const instance = axios.create(BASE_CONFIG);

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      throw new UnauthenticatedError("Unauthorized", error.response);
    }
    return Promise.reject(error);
  }
);

export default instance;
