import instance from "../../api/http_client";
import { Patient } from "./types";

export function getPatientInformation() {
  return instance
    .get("consent/patient/detail")
    .then((res) => Patient.fromJson(res.data));
}

export function updatePatient(
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  streetAddress: string,
  city: string,
  state: string,
  zip: string
) {
  return instance.put("consent/patient/update", {
    first_name: firstName,
    last_name: lastName,
    email: email,
    phone_number: phone,
    street_address: streetAddress,
    city: city,
    state: state,
    zip_code: zip,
  });
}
